import clsx from "clsx";
import String from "@gotombola/react-ds/lib/atoms/String";
import React, {useMemo} from "react";
import {Presult} from "@gotombola/sdk";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

function buildGiftIndexMap(items: any) {
    const [_, map] = items.reduce((acc: any, item: any) => {
        for (let i = 0; i < (item.quantity || 1); i++) {
            acc[1][`${item.id}/${i + 1}`] = acc[0] + 1;
            acc[0]++;
        }
        return acc;
    }, [0, {}]);
    return map;
}
export function PresultInfos({presult, game}: PresultWinnerInfosProps) {
    const {t} = useTypeTranslation('game', game);
    const giftIndexMap = useMemo(() => buildGiftIndexMap(game?.rankedBunches?.items || []), [game?.rankedBunches?.items]);
    return (
        <>
            {presult.winningTicketCount > 1 && <div className={'font-go-text text-go-text text-2xl text-green-600'}><String>{`:gift:&nbsp;&nbsp;${t('presult_multiple_winner_public_page_section', {quantity: presult.winningTicketCount})}&nbsp;&nbsp;:gift:`}</String></div>}
            {presult.winningTicketCount >= 1 && presult.wonPrizesDetails?.prizes?.map(({ticketCode, bunchName, bunch, bunchRank}: any, index: number) => {
                return (
                    <div key={index} className={clsx('font-go-text text-go-text text-center', game?.type === 'secretdraw' && 'text-3xl')}>{t(bunchRank > 1 ? 'presult_page_prize_detail_with_rank' : 'presult_page_prize_detail', {ticketCode, bunchName, bunchRank, rank: giftIndexMap[`${bunch}/${bunchRank}`] || '?'})}</div>
                )
            })}
            {'secretdraw' === game?.type && <div className={'font-go-text text-go-text text-lg text-red-500'}><String>{t('presult_winner_be_quiet')}</String></div>}
        </>
    )
}

export interface PresultWinnerInfosProps {
    presult: Presult;
    game: any;
}
export default PresultInfos;
