import React, {useCallback, useMemo} from 'react';
import zones from '../ad-zones';
import {useMode} from "../../../utils/common";
import Pixel from "@gotombola/react-ds/lib/atoms/Pixel";
import TypePage from "@gotombola/react-ds/lib/pages/TypePage";
import GamePageWrapper from "@gotombola/react-ds/lib/organisms/GamePageWrapper";
import editablePage from "@gotombola/react-ds/lib/hocs/editablePage";
import {AdZonesProvider} from "@gotombola/react-ad/lib/AdZonesProvider";
import DisplayPresultGameForm from "../molecules/DisplayPresultGameForm";

export const PresultShowcasePage = editablePage('game', [], ({staticContext: {presult, game, locale, mode, ad, gifts}}: any) => {
    const {pixel, push, publicPages, templates} = useMode(mode);

    const pixelProps = useMemo(() => ({gc: game.code}), [game.code]);
    const pushProps = useMemo(() => ({mode: game.status, game}), [game, game.status]);
    const extraFn = useCallback(() => ({presult, gifts}), [presult]);

    return (
        <AdZonesProvider components={zones} settings={ad}>
            <GamePageWrapper game={game} locale={locale}>
                <TypePage type={'game'} doc={game} page={'presult_showcase'} locale={locale}
                          publicPages={publicPages} templates={templates}
                          pixels={pixel} pushs={push}
                          pixelProps={pixelProps} pushProps={pushProps}
                          extraFn={extraFn}
                          noindex
                >
                    <DisplayPresultGameForm game={game} presult={presult} gifts={gifts} />
                    <Pixel app={pixel.app} event={pixel.pages.presult_showcase} />
                </TypePage>
            </GamePageWrapper>
        </AdZonesProvider>
    );
});

export default PresultShowcasePage;
