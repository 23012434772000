import TextBlock from "@gotombola/react-ds/lib/blocks/TextBlock";
import React from "react";
import {Presult} from "@gotombola/sdk";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

export function PresultMessages({presult, game}: PresultMessagesProps) {
    const {t} = useTypeTranslation('game', game);

    return (
        <>
            {!!presult.winningTicketCount && <>
                {!!game.winnerResultThanks && <TextBlock text={game.winnerResultThanks} className={'text-center sm:text-left'} />}
                {!!game.participantResultThanks && <TextBlock text={game.participantResultThanks} className={'text-center sm:text-left'} />}
                {!!game.winnerResultMessage && <TextBlock text={game.winnerResultMessage} className={'text-center sm:text-left'} />}
                {!!game.participantResultMessage && <TextBlock text={game.participantResultMessage} className={'text-center sm:text-left'} />}
                {!!game.winnerResultInfos && <TextBlock title={t('game_detailedinfos_infos_label')} text={game.winnerResultInfos} inverted className={'text-center sm:text-left'} />}
                {!!game.participantResultInfos && <TextBlock title={t('game_detailedinfos_infos_label')} text={game.participantResultInfos} inverted className={'text-center sm:text-left'} />}
                {!!game.winnerResultWarning && <TextBlock title={t('game_detailedinfos_warning_label')} text={game.winnerResultWarning} inverted className={'text-center sm:text-left'} />}
                {!!game.participantResultWarning && <TextBlock title={t('game_detailedinfos_warning_label')} text={game.participantResultWarning} inverted className={'text-center sm:text-left'} />}
            </>}
            {!presult.winningTicketCount && <>
                {!!game.looserResultThanks && <TextBlock text={game.looserResultThanks} className={'text-center sm:text-left'} />}
                {!!game.participantResultThanks && <TextBlock text={game.participantResultThanks} className={'text-center sm:text-left'} />}
                {!!game.looserResultMessage && <TextBlock text={game.looserResultMessage} className={'text-center sm:text-left'} />}
                {!!game.participantResultMessage && <TextBlock text={game.participantResultMessage} className={'text-center sm:text-left'} />}
                {!!game.looserResultInfos && <TextBlock title={t('game_detailedinfos_infos_label')} text={game.looserResultInfos} inverted className={'text-center sm:text-left'} />}
                {!!game.participantResultInfos && <TextBlock title={t('game_detailedinfos_infos_label')} text={game.participantResultInfos} inverted className={'text-center sm:text-left'} />}
                {!!game.looserResultWarning && <TextBlock title={t('game_detailedinfos_warning_label')} text={game.looserResultWarning} inverted className={'text-center sm:text-left'} />}
                {!!game.participantResultWarning && <TextBlock title={t('game_detailedinfos_warning_label')} text={game.participantResultWarning} inverted className={'text-center sm:text-left'} />}
            </>}
        </>
    );
}

export interface PresultMessagesProps {
    presult: Presult;
    game: any;
}

export default PresultMessages;
