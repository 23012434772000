export default {
    game_presult_public: {
        "default": {
            "dsn": "type_effects;header=game_header;common_spacer;main=common_title,type_demo_banner;footer=game_footer,common_tenant_footer",
            "params": {
                "common_title": {
                    "title": ({presult}: any) => presult?.winningTicketCount > 0 ? "game_presult_public_title_winner" : "game_presult_public_title_looser",
                }
            }
        },
    },
    game_presult_showcase: {
        "default": {
            "dsn": "type_effects;header=game_header;common_spacer;main=common_title,type_demo_banner;footer=game_footer,common_tenant_footer",
            "params": {
                "type_demo_banner": {
                    "forceDemo": true,
                },
                "common_title": {
                    "title": ({presult}: any) => presult?.winningTicketCount > 0 ? "game_presult_public_title_winner" : "game_presult_public_title_looser",
                }
            }
        },
    },
}
