import React, {useMemo} from "react";
import PresultAd from "./PresultAd";
import PresultInfos from "./PresultInfos";
import PresultGifts from "./PresultGifts";
import PresultMessages from "./PresultMessages";
import PresultSponsors from "./PresultSponsors";
import PresultLoosingTickets from "./PresultLoosingTickets";
import {GameContextProvider} from '@gotombola/react-contexts-type/lib/contexts/GameContext';
import CollectModeGameModule from "@gotombola/react-ds/lib/template-modules/game/CollectModeGameModule";

export function DisplayPresultGameForm({ game, presult, gifts }: DisplayPresultGameFormProps) {
    const props = useMemo(() => ({presult, game, gifts}), [presult, game, gifts]);
    const gcp = useMemo(() => ({game}), [game]);

    return (
        <div className={'flex flex-col items-center w-full px-4 md:px-16 sm:pb-4 mb-24 space-y-6'}>
            <PresultInfos {...props} />
            <PresultGifts nocontact={!!game.collectModeNoPublicContact || !!game.collectModeEmail} {...props} />
            <div className={'sm:w-4/5 md:w-3/4 w-full'}>
                <PresultMessages {...props} />
                <GameContextProvider value={gcp}>
                    <PresultAd {...props} />
                </GameContextProvider>
            </div>
            <CollectModeGameModule {...props} />
            <PresultLoosingTickets {...props} />
            <PresultSponsors {...props} />
        </div>
    );
}

export interface DisplayPresultGameFormProps {
    game: any;
    presult: any;
    gifts?: any;
}

export default DisplayPresultGameForm;
