import * as pages from './pages';

const routeMap = {
    presult_: {
        en_US: '/',
    },
    presult_showcase: {
        en_US: '/showcase/:ppt',
    },
}

const routeList = [
    'presult_showcase',
    {name: 'presult_', ssr: 'presult_public', component: pages.presult_public},
];

export default {configure: () => ({routeMap, routeList, pages})};
